// src/components/outbound/CampaignView.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert
} from '@mui/material';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

const CampaignView = () => {
  const [campaigns, setCampaigns] = useState([]);

  // For creating a new campaign
  const [dialogOpen, setDialogOpen] = useState(false);
  const [campaignName, setCampaignName] = useState('');
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [contacts, setContacts] = useState([]);
  
  // Either pick an existing protocol or define a brand-new approach
  const [selectedProtocolId, setSelectedProtocolId] = useState('');
  const [protocols, setProtocols] = useState([]);
  
  // Possibly store new approach data if we allow user to create an approach inline
  const [customProtocolName, setCustomProtocolName] = useState('');
  const [customProtocolDescription, setCustomProtocolDescription] = useState('');
  
  // For illustration, if we do a simple multi-step approach (like default)
  // or let them pick from existing approach IDs

  // Snackbar
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info'
  });

  // -----------------------------------------
  // useEffect: fetch campaigns, contacts, protocols
  // -----------------------------------------
  useEffect(() => {
    fetchCampaigns();
    fetchContacts();
    fetchProtocols();
  }, []);

  const fetchCampaigns = async () => {
    try {
      // GET /outbound/campaigns
      const res = await fetch(`${API_BASE_URL}/outbound/campaigns`);
      if (!res.ok) throw new Error('Failed to fetch campaigns');
      const data = await res.json();
      setCampaigns(data.campaigns || []);
    } catch (err) {
      console.error('Error fetching campaigns:', err);
      showSnackbar('Failed to fetch campaigns.', 'error');
    }
  };

  const fetchContacts = async () => {
    try {
      // GET /contacts
      const res = await fetch(`${API_BASE_URL}/contacts`);
      if (!res.ok) throw new Error('Failed to fetch contacts');
      const data = await res.json();
      setContacts(data.contacts || []);
    } catch (err) {
      console.error('Error fetching contacts:', err);
      showSnackbar('Failed to fetch contacts.', 'error');
    }
  };

  const fetchProtocols = async () => {
    try {
      // GET /outbound/approach_protocols
      const res = await fetch(`${API_BASE_URL}/outbound/approach_protocols`);
      if (!res.ok) throw new Error('Failed to fetch protocols');
      const data = await res.json();
      setProtocols(data.protocols || []);
    } catch (err) {
      console.error('Error fetching protocols:', err);
      showSnackbar('Failed to fetch protocols.', 'error');
    }
  };

  // -----------------------------------------
  // show/hide Snackbar
  // -----------------------------------------
  const showSnackbar = (message, severity='info') => {
    setSnackbar({ open: true, message, severity });
  };
  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  // -----------------------------------------
  // handle dialog open/close
  // -----------------------------------------
  const createCampaign = () => {
    setDialogOpen(true);
  };
  const closeDialog = () => {
    setDialogOpen(false);
    // clear form
    setCampaignName('');
    setSelectedContacts([]);
    setSelectedProtocolId('');
    setCustomProtocolName('');
    setCustomProtocolDescription('');
  };

  // -----------------------------------------
  // submit new campaign
  // -----------------------------------------
  const handleCreateCampaign = async () => {
    if (!campaignName.trim()) {
      alert('Please provide a campaign name.');
      return;
    }
    if (selectedContacts.length === 0) {
      alert('Please pick at least one contact.');
      return;
    }
    
    // If user picks an existing protocol, we can just reference it
    let approachProtocolId = selectedProtocolId;
    
    // If user wants to define a brand new approach inline
    // then we might call /outbound/approach_protocols (POST)
    // to create it first, then use the returned protocol_id
    if (!approachProtocolId && customProtocolName.trim()) {
      // create a new approach protocol
      try {
        const payload = {
          name: customProtocolName,
          description: customProtocolDescription,
          // default steps or empty
          steps: [
            // e.g. your default approach or user-provided steps
            { channel: 'email', delayDays: 0 },
            { channel: 'sms', delayDays: 2 },
            // ...
          ]
        };
        const createProtoRes = await fetch(`${API_BASE_URL}/outbound/approach_protocols`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload)
        });
        if (!createProtoRes.ok) throw new Error('Failed to create approach protocol');
        const newProtoData = await createProtoRes.json();
        approachProtocolId = newProtoData.id;  // or whatever your API returns
      } catch (err) {
        console.error('Error creating new approach protocol inline:', err);
        showSnackbar('Error creating approach protocol.', 'error');
        return;
      }
    }
    
    if (!approachProtocolId) {
      alert('Either select an existing protocol or provide a new protocol name.');
      return;
    }

    // Now create the campaign referencing approachProtocolId, selectedContacts, etc.
    try {
      const body = {
        campaignName,
        approachProtocolId,
        contactIds: selectedContacts
      };
      const res = await fetch(`${API_BASE_URL}/outbound/campaigns`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      });
      if (!res.ok) throw new Error('Failed to create campaign');
      showSnackbar('Campaign created!', 'success');
      closeDialog();
      fetchCampaigns(); // refresh
    } catch (err) {
      console.error('Error creating campaign:', err);
      showSnackbar('Failed to create campaign.', 'error');
    }
  };

  // -----------------------------------------
  // Render
  // -----------------------------------------
  return (
    <Box p={3}>
      <Card sx={{ backgroundColor: '#1e1e1e', mb: 4 }}>
        <CardHeader
          avatar={<Avatar sx={{ bgcolor: 'secondary.main' }}>C</Avatar>}
          title={<Typography variant="h5">Campaigns</Typography>}
          subheader={<Typography variant="body2" color="text.secondary">
            Launch multi-contact approach protocols here.
          </Typography>}
        />
        <CardContent>
          {/* Button to open creation dialog */}
          <Button variant="contained" onClick={createCampaign} sx={{ mb: 2 }}>
            New Campaign
          </Button>

          {/* List existing campaigns */}
          {campaigns.length === 0 ? (
            <Typography>No campaigns found.</Typography>
          ) : (
            campaigns.map((c) => (
              <Box key={c.id} sx={{ mb: 2, p: 2, border: '1px solid #444' }}>
                <Typography variant="body1">
                  Campaign Name: {c.campaignName}
                </Typography>
                <Typography variant="body2">
                  Approach Protocol: {c.approachProtocolId}
                </Typography>
                <Typography variant="body2">
                  Contacts: { (c.contactIds || []).join(', ') }
                </Typography>
              </Box>
            ))
          )}
        </CardContent>
      </Card>

      {/* Dialog for creating a new campaign */}
      {dialogOpen && (
        <div>
          <Dialog open={dialogOpen} onClose={closeDialog} maxWidth="sm" fullWidth>
            <DialogTitle>New Campaign</DialogTitle>
            <DialogContent dividers>
              <TextField
                label="Campaign Name"
                variant="filled"
                fullWidth
                sx={{ mb: 2 }}
                value={campaignName}
                onChange={(e) => setCampaignName(e.target.value)}
              />

              {/* Multi-select for contacts */}
              <FormControl variant="filled" fullWidth sx={{ mb: 2 }}>
                <InputLabel>Select Contacts</InputLabel>
                <Select
                  multiple
                  value={selectedContacts}
                  onChange={(e) => setSelectedContacts(e.target.value)}
                  renderValue={(selected) => {
                    // Show a list of contact names
                    return selected.map((cid) => {
                      const c = contacts.find((x) => x.contact_id === cid);
                      return c ? `${c.first_name} ${c.last_name}` : cid;
                    }).join(', ');
                  }}
                >
                  {contacts.map((c) => (
                    <MenuItem key={c.contact_id} value={c.contact_id}>
                      <Checkbox checked={selectedContacts.indexOf(c.contact_id) > -1} />
                      <ListItemText
                        primary={`${c.first_name} ${c.last_name}`}
                        secondary={c.email}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Typography variant="body2" sx={{ mt: 1, mb: 1 }}>
                Choose an existing approach protocol or create a new one:
              </Typography>

              {/* Existing protocol select */}
              <FormControl variant="filled" fullWidth sx={{ mb: 2 }}>
                <InputLabel>Existing Protocol</InputLabel>
                <Select
                  value={selectedProtocolId}
                  onChange={(e) => setSelectedProtocolId(e.target.value)}
                >
                  <MenuItem value="">
                    <em>-- none --</em>
                  </MenuItem>
                  {protocols.map((p) => (
                    <MenuItem key={p.id} value={p.id}>
                      {p.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Or define a new approach in-line */}
              <TextField
                label="(New) Approach Protocol Name"
                variant="filled"
                fullWidth
                sx={{ mb: 2 }}
                value={customProtocolName}
                onChange={(e) => setCustomProtocolName(e.target.value)}
              />
              <TextField
                label="(New) Approach Protocol Description"
                variant="filled"
                fullWidth
                multiline
                rows={2}
                sx={{ mb: 2 }}
                value={customProtocolDescription}
                onChange={(e) => setCustomProtocolDescription(e.target.value)}
              />
              <Typography variant="body2">
                If you fill these in and don’t pick an existing protocol, we’ll create a new approach protocol for you.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} color="inherit">
                Cancel
              </Button>
              <Button variant="contained" onClick={handleCreateCampaign} color="primary">
                Create Campaign
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CampaignView;
