// src/components/outbound/ApproachProtocolForm.js

import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Typography,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { supabase } from '../../supabaseClient';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

// For brevity, define a helper to guess the mail domain from BASE_URL
function getMailDomain() {
  if (API_BASE_URL.toLowerCase().includes('dev')) {
    return 'mail-dev.echobach.com';
  }
  return 'mail.echobach.com';
}

const DEFAULT_STEPS = [
  { channel: 'email', delayDays: 0, template: '' },
  { channel: 'sms', delayDays: 2, template: '' },
  { channel: 'phone', delayDays: 4, template: '' },
  { channel: 'email', delayDays: 7, template: '' },
  { channel: 'phone', delayDays: 10, template: '' },
  { channel: 'email', delayDays: 13, template: '' },
  { channel: 'sms', delayDays: 16, template: '' },
  { channel: 'phone', delayDays: 20, template: '' }
];

const ApproachProtocolForm = ({ onCreate }) => {
  // Remove the useAuthToken hook and use local state instead
  const [authToken, setAuthToken] = useState(null);

  // BASIC FORM STATES
  const [protocolName, setProtocolName] = useState('');
  const [description, setDescription] = useState('');

  // SINGLE vs MULTI
  const [isSingleStep, setIsSingleStep] = useState(false);
  const [useDefaultApproach, setUseDefaultApproach] = useState(true);
  const [steps, setSteps] = useState([]); // for custom multi-step

  // SINGLE-STEP APPROACH FIELDS
  const [singleStepChannel, setSingleStepChannel] = useState('email');
  const [singleStepTemplate, setSingleStepTemplate] = useState('');

  // CONTACTS & SELECTED CONTACT
  const [contacts, setContacts] = useState([]);
  const [selectedContactId, setSelectedContactId] = useState('');
  const [selectedContact, setSelectedContact] = useState(null);

  // TWILIO PHONES & SELECTED
  const [twilioPhones, setTwilioPhones] = useState([]);
  const [selectedFromNumber, setSelectedFromNumber] = useState('');

  // For multi-step, store default "From" phone/email at the protocol level
  const [defaultFromPhone, setDefaultFromPhone] = useState('');
  const [defaultFromEmail, setDefaultFromEmail] = useState('');

  // Fetch user’s available email aliases from your "list_assistants_with_alias" endpoint
  const [agentEmailAliases, setAgentEmailAliases] = useState([]);
  // Controls whether the user picks an alias from the list or types a custom email
  const [useCustomEmail, setUseCustomEmail] = useState(false);

  // Load Supabase session token on mount
  useEffect(() => {
    const loadSession = async () => {
      try {
        const { data } = await supabase.auth.getSession();
        const token = data.session?.access_token;
        setAuthToken(token);
      } catch (err) {
        console.error('Error getting session token:', err);
      }
    };
    loadSession();
  }, []);

  // Once token is available, fetch contacts, Twilio phones, and email aliases
  useEffect(() => {
    if (authToken) {
      fetchContacts();
      fetchTwilioPhones();
      fetchEmailAliases();
    }
  }, [authToken]);

  // 1) Fetch user contacts
  const fetchContacts = async () => {
    if (!authToken) return;
    try {
      const res = await axios.get(`${API_BASE_URL}/contacts/`, {
        headers: { Authorization: `Bearer ${authToken}` }
      });
      setContacts(res.data.contacts || []);
    } catch (err) {
      console.error('Error fetching contacts:', err);
    }
  };

  // 2) Fetch Twilio phone numbers
  const fetchTwilioPhones = async () => {
    if (!authToken) return;
    try {
      const res = await axios.get(`${API_BASE_URL}/bach_registry/get-bachs/`, {
        headers: { Authorization: `Bearer ${authToken}` }
      });
      const allBachs = res.data || [];
      const phoneBachs = allBachs.filter(b => b.device_type === 'twilio_phone');
      setTwilioPhones(phoneBachs);
    } catch (err) {
      console.error('Error fetching twilio phones:', err);
    }
  };

  // 3) Fetch user's email aliases (assistants + their alias)
  const fetchEmailAliases = async () => {
    if (!authToken) return;
    try {
      const res = await axios.get(`${API_BASE_URL}/email_management/list_assistants_with_alias`, {
        headers: { Authorization: `Bearer ${authToken}` }
      });
      /*
        Each item is like: {
          assistant_id: string,
          assistant_name: string,
          email_alias: string | null
        }
      */
      setAgentEmailAliases(res.data || []);
    } catch (err) {
      console.error('Error fetching email aliases:', err);
    }
  };

  const handleSelectContact = (contactId) => {
    setSelectedContactId(contactId);
    const found = contacts.find(c => c.contact_id === contactId);
    setSelectedContact(found || null);
  };

  const addStep = () => {
    setSteps(prev => [...prev, { channel: 'email', delayDays: 0, template: '' }]);
  };

  // Helper: For single-step, decide which field from contact to pass
  const getContactTarget = () => {
    if (!selectedContact) return '';
    if (singleStepChannel === 'email') {
      return selectedContact.email || '';
    }
    // For SMS or phone channels
    return selectedContact.phone_number || '';
  };

  const handleCreate = () => {
    if (!selectedContactId) {
      alert('Please select a contact.');
      return;
    }
    if (!protocolName.trim()) {
      alert('Please provide a name/label for the approach.');
      return;
    }

    // SINGLE-STEP
    if (isSingleStep) {
      const finalSteps = [
        { channel: singleStepChannel, delayDays: 0, template: singleStepTemplate }
      ];

      // For phone or SMS, require a "from" number
      let fromNumber = '';
      if (singleStepChannel === 'phone' || singleStepChannel === 'sms') {
        if (!selectedFromNumber) {
          alert('Please select a "From Number" for this channel.');
          return;
        }
        fromNumber = selectedFromNumber;
      }

      // Decide which contact field to use based on channel
      const contactTarget = getContactTarget();

      const approachData = {
        contactId: selectedContactId,
        contactPhoneNumber: selectedContact?.phone_number || '',
        contactEmail: selectedContact?.email || '',
        name: protocolName,
        description,
        steps: finalSteps,
        isSingleStep: true,
        singleStepChannel,
        fromNumber,
        singleStepTemplate,
        contactTarget
      };

      onCreate(approachData);
      resetForm();
      return;
    }

    // MULTI-STEP
    const finalSteps = useDefaultApproach ? DEFAULT_STEPS : steps;

    const approachData = {
      contactId: selectedContactId,
      contactPhoneNumber: selectedContact?.phone_number || '',
      contactEmail: selectedContact?.email || '',
      name: protocolName,
      description,
      steps: finalSteps,
      isSingleStep: false,
      defaultFromEmail,
      defaultFromPhone
    };

    onCreate(approachData);
    resetForm();
  };

  const resetForm = () => {
    setSelectedContactId('');
    setSelectedContact(null);
    setProtocolName('');
    setDescription('');
    setIsSingleStep(false);
    setUseDefaultApproach(true);
    setSteps([]);
    setSingleStepChannel('email');
    setSingleStepTemplate('');
    setSelectedFromNumber('');
    setDefaultFromPhone('');
    setDefaultFromEmail('');
    setUseCustomEmail(false);
  };

  const handleToggleSingleStep = (e) => {
    setIsSingleStep(e.target.checked);
  };

  const handleToggleUseDefault = (e) => {
    const checked = e.target.checked;
    setUseDefaultApproach(checked);
    if (!checked) {
      setSteps([...DEFAULT_STEPS]);
    } else {
      setSteps([]);
    }
  };

  const contactMenuItems = contacts.map((c) => (
    <MenuItem key={c.contact_id} value={c.contact_id}>
      {`${c.first_name || ''} ${c.last_name || ''}`.trim()} 
      {c.phone_number ? ` (${c.phone_number})` : ''}
      {c.email ? ` <${c.email}>` : ''}
    </MenuItem>
  ));

  const phoneMenuItems = twilioPhones.map(ph => (
    <MenuItem key={ph.device_id} value={ph.device_id}>
      {ph.alias || ph.device_id}
    </MenuItem>
  ));

  const requiresFromNumber = isSingleStep && ['phone', 'sms'].includes(singleStepChannel);

  // Build a list of email alias options
  const mailDomain = getMailDomain();
  const emailAliasMenuItems = agentEmailAliases
    .filter(a => a.email_alias)
    .map(a => {
      const fullEmail = `${a.email_alias}@${mailDomain}`;
      return (
        <MenuItem key={a.assistant_id} value={fullEmail}>
          {`${a.assistant_name} (${fullEmail})`}
        </MenuItem>
      );
    });

  // Single-step button label
  const singleStepButtonLabel = (isSingleStep && singleStepChannel === 'phone')
    ? 'Launch Approach'
    : 'Create Approach';

  return (
    <Card sx={{ mb: 3, backgroundColor: '#2a2a2a' }}>
      <CardHeader
        avatar={<Avatar sx={{ bgcolor: 'secondary.main' }}>A</Avatar>}
        title={<Typography variant="h6">Create a New Approach</Typography>}
        subheader={
          <Typography variant="body2" color="text.secondary">
            Pick a contact, define your objective, and choose single vs. multi-step outreach.
          </Typography>
        }
      />
      <CardContent>
        {/* Select Contact */}
        <FormControl variant="filled" fullWidth sx={{ mb: 2 }}>
          <InputLabel>Select Contact</InputLabel>
          <Select
            value={selectedContactId}
            onChange={(e) => handleSelectContact(e.target.value)}
          >
            <MenuItem value="">
              <em>-- No contact selected --</em>
            </MenuItem>
            {contactMenuItems}
          </Select>
        </FormControl>

        {/* Protocol/Approach Name (Objective) */}
        <TextField
          label="Approach Objective"
          variant="filled"
          fullWidth
          sx={{ mb: 2 }}
          value={protocolName}
          onChange={(e) => setProtocolName(e.target.value)}
        />

        {/* Description */}
        <TextField
          label="Description (optional)"
          variant="filled"
          fullWidth
          multiline
          rows={2}
          sx={{ mb: 2 }}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />

        {/* Toggle Single vs. Multi Step */}
        <FormControlLabel
          control={<Switch checked={isSingleStep} onChange={handleToggleSingleStep} />}
          label="Use a Single-Step Approach"
          sx={{ mb: 2 }}
        />

        {isSingleStep ? (
          // SINGLE-STEP UI
          <Box sx={{ p: 2, border: '1px solid #444', backgroundColor: '#1a1a1a' }}>
            <Typography variant="body1" sx={{ mb: 1 }}>
              Single Step (Choose channel + optional template):
            </Typography>
            <FormControl variant="filled" fullWidth sx={{ mb: 2 }}>
              <InputLabel>Channel</InputLabel>
              <Select
                value={singleStepChannel}
                onChange={(e) => setSingleStepChannel(e.target.value)}
              >
                <MenuItem value="email">Email</MenuItem>
                <MenuItem value="sms">SMS</MenuItem>
                <MenuItem value="phone">Phone</MenuItem>
              </Select>
            </FormControl>

            {/* From phone if channel is phone or SMS */}
            {requiresFromNumber && (
              <FormControl variant="filled" fullWidth sx={{ mb: 2 }}>
                <InputLabel>From Number</InputLabel>
                <Select
                  value={selectedFromNumber}
                  onChange={(e) => setSelectedFromNumber(e.target.value)}
                >
                  <MenuItem value="">
                    <em>-- Select a Twilio Phone --</em>
                  </MenuItem>
                  {phoneMenuItems}
                </Select>
              </FormControl>
            )}

            <TextField
              label="Message Template (optional)"
              variant="filled"
              fullWidth
              multiline
              rows={2}
              value={singleStepTemplate}
              onChange={(e) => setSingleStepTemplate(e.target.value)}
            />
          </Box>
        ) : (
          // MULTI-STEP UI
          <>
            <FormControlLabel
              control={<Switch checked={useDefaultApproach} onChange={handleToggleUseDefault} />}
              label="Use Default 8-Step Multi-Channel Approach"
              sx={{ mb: 2 }}
            />

            {/* "From" fields for multi-step */}
            <Box sx={{ p: 2, border: '1px solid #444', backgroundColor: '#1a1a1a', mb: 2 }}>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Defaults for multi-step outbound:
              </Typography>

              {/* From Email selection */}
              <FormControl variant="filled" fullWidth sx={{ mb: 2 }}>
                <InputLabel>Default From Email (optional)</InputLabel>
                <Select
                  value={useCustomEmail ? '__CUSTOM__' : defaultFromEmail}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (val === '__CUSTOM__') {
                      setUseCustomEmail(true);
                      setDefaultFromEmail('');
                    } else {
                      setUseCustomEmail(false);
                      setDefaultFromEmail(val);
                    }
                  }}
                >
                  <MenuItem value="">
                    <em>-- None --</em>
                  </MenuItem>
                  {emailAliasMenuItems}
                  <MenuItem value="__CUSTOM__">
                    <em>-- Other / Custom Email --</em>
                  </MenuItem>
                </Select>
              </FormControl>

              {/* Show text field if user chooses "Other / Custom" */}
              {useCustomEmail && (
                <TextField
                  label="Custom From Email"
                  variant="filled"
                  fullWidth
                  sx={{ mb: 2 }}
                  value={defaultFromEmail}
                  onChange={(e) => setDefaultFromEmail(e.target.value)}
                />
              )}

              {/* From Phone selection */}
              <FormControl variant="filled" fullWidth>
                <InputLabel>Default From Phone (optional)</InputLabel>
                <Select
                  value={defaultFromPhone}
                  onChange={(e) => setDefaultFromPhone(e.target.value)}
                >
                  <MenuItem value="">
                    <em>-- None --</em>
                  </MenuItem>
                  {phoneMenuItems}
                </Select>
              </FormControl>
            </Box>

            {/* Steps */}
            {useDefaultApproach ? (
              <Box sx={{ p: 2, border: '1px solid #444', backgroundColor: '#1a1a1a' }}>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  Default Approach Steps (read-only):
                </Typography>
                {DEFAULT_STEPS.map((step, idx) => (
                  <Typography key={idx} variant="body2" sx={{ mb: 0.5 }}>
                    {`Step ${idx + 1}: channel = ${step.channel}, delayDays = ${step.delayDays}`}
                  </Typography>
                ))}
              </Box>
            ) : (
              <Box>
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={addStep}
                  sx={{ mb: 2 }}
                >
                  Add Step
                </Button>
                {steps.map((step, index) => (
                  <Box key={index} sx={{ p: 2, mb: 1, border: '1px solid #444' }}>
                    <Typography variant="body1">Step {index + 1}</Typography>
                    <TextField
                      label="Channel (email, sms, phone)"
                      variant="filled"
                      fullWidth
                      sx={{ mb: 1 }}
                      value={step.channel}
                      onChange={(e) => {
                        const updated = [...steps];
                        updated[index].channel = e.target.value;
                        setSteps(updated);
                      }}
                    />
                    <TextField
                      label="Delay (days after previous step)"
                      variant="filled"
                      fullWidth
                      sx={{ mb: 1 }}
                      value={step.delayDays}
                      onChange={(e) => {
                        const updated = [...steps];
                        updated[index].delayDays = parseInt(e.target.value) || 0;
                        setSteps(updated);
                      }}
                    />
                    <TextField
                      label="Message Template (optional)"
                      variant="filled"
                      fullWidth
                      multiline
                      rows={2}
                      value={step.template}
                      onChange={(e) => {
                        const updated = [...steps];
                        updated[index].template = e.target.value;
                        setSteps(updated);
                      }}
                    />
                  </Box>
                ))}
              </Box>
            )}
          </>
        )}
      </CardContent>

      {/* ACTION BUTTON */}
      <CardActions>
        <Button variant="contained" fullWidth onClick={handleCreate}>
          {singleStepButtonLabel}
        </Button>
      </CardActions>
    </Card>
  );
};

export default ApproachProtocolForm;
