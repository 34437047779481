// src/components/outbound/ApproachProtocolView.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Typography,
  Snackbar,
  Alert
} from '@mui/material';
import axios from 'axios';

import ApproachProtocolForm from './ApproachProtocolForm';
import ApproachProtocolList from './ApproachProtocolList';
import { supabase } from '../../supabaseClient';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

const ApproachProtocolView = () => {
  const [protocols, setProtocols] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [authToken, setAuthToken] = useState(null);

  useEffect(() => {
    // Fetch the Supabase session token on mount
    const loadSession = async () => {
      try {
        const { data } = await supabase.auth.getSession();
        const token = data.session?.access_token;
        setAuthToken(token);
      } catch (err) {
        console.error('Error getting session token:', err);
      }
    };
    loadSession();
  }, []);

  useEffect(() => {
    if (authToken) {
      fetchProtocols();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

  // 1) Fetch existing protocols from the backend
  const fetchProtocols = async () => {
    if (!authToken) return;
    try {
      const res = await axios.get(`${API_BASE_URL}/approach/protocols`, {
        headers: { Authorization: `Bearer ${authToken}` }
      });
      setProtocols(res.data); // array of approach protocols
    } catch (err) {
      console.error('Error fetching protocols:', err);
      showSnackbar('Failed to load protocols.', 'error');
    }
  };

  // 2) Create or Launch an approach (single-step or multi-step)
  const createProtocol = async (newApproach) => {
    /*
      "newApproach" structure (from ApproachProtocolForm):
      {
        isSingleStep: true/false,
        singleStepChannel: 'email' | 'sms' | 'phone',
        fromNumber: '+1...',        // for single-step phone/sms
        singleStepTemplate: '...',  // single-step message
        contactPhoneNumber: '+1...',
        contactEmail: 'someone@example.com',
        name: 'My Approach Name',   // also used as objective
        description: '...',
        steps: [...],               // multi-step steps array
        defaultFromEmail: 'alias@mail.echobach.com',
        defaultFromPhone: '+12345678900',
        ...
      }
    */

    try {
      if (!authToken) {
        showSnackbar('Not authenticated.', 'error');
        return;
      }

      // SINGLE-STEP LOGIC
      if (newApproach.isSingleStep) {
        // 2a) Single-step phone call
        if (newApproach.singleStepChannel === 'phone') {
          if (!newApproach.contactPhoneNumber) {
            showSnackbar('Contact has no phone number.', 'error');
            return;
          }
          if (!newApproach.fromNumber) {
            showSnackbar('No "From Number" chosen.', 'error');
            return;
          }

          const phonePayload = {
            from_number: newApproach.fromNumber,
            to_number: newApproach.contactPhoneNumber,
            call_prompt: newApproach.description || newApproach.name
          };

          const res = await axios.post(
            `${API_BASE_URL}/outbound_calls/initiate_outbound_call`,
            phonePayload,
            { headers: { Authorization: `Bearer ${authToken}` } }
          );
          showSnackbar(`Outbound call launched! Sid: ${res.data.call_sid}`, 'success');
        }
        // 2b) Single-step SMS or Email
        else if (newApproach.singleStepChannel === 'sms' || newApproach.singleStepChannel === 'email') {
          let toNumber = '';
          if (newApproach.singleStepChannel === 'email') {
            toNumber = newApproach.contactEmail || '';
            if (!toNumber) {
              showSnackbar('Contact has no email.', 'error');
              return;
            }
          } else {
            toNumber = newApproach.contactPhoneNumber || '';
            if (!toNumber) {
              showSnackbar('Contact has no phone number.', 'error');
              return;
            }
          }

          const msgPayload = {
            from_number: newApproach.fromNumber || '',
            to_number: toNumber,
            call_prompt: newApproach.description || newApproach.name,
            channel: newApproach.singleStepChannel,
            template: newApproach.singleStepTemplate
          };

          await axios.post(
            `${API_BASE_URL}/outbound_msgs/initiate_outbound_msg`,
            msgPayload,
            { headers: { Authorization: `Bearer ${authToken}` } }
          );
          showSnackbar(`Outbound ${newApproach.singleStepChannel} sent!`, 'success');
        }
      }

      // MULTI-STEP LOGIC
      else {
        // 3a) Create new ApproachProtocol
        const protocolPayload = {
          name: newApproach.name,
          description: newApproach.description,
          steps: newApproach.steps,
          is_default: false,
          default_from_phone: newApproach.defaultFromPhone || null,
          default_from_email: newApproach.defaultFromEmail || null
        };

        const protocolRes = await axios.post(
          `${API_BASE_URL}/approach/protocols`,
          protocolPayload,
          { headers: { Authorization: `Bearer ${authToken}` } }
        );
        const createdProtocol = protocolRes.data; // e.g. { approach_protocol_id, ... }

        // 3b) Create an ApproachInstance referencing that protocol
        const instancePayload = {
          approach_protocol_id: createdProtocol.approach_protocol_id,
          contact_email: newApproach.contactEmail || null,
          contact_phone: newApproach.contactPhoneNumber || null,
          objective: newApproach.name || "No objective given"
        };

        await axios.post(
          `${API_BASE_URL}/approach/instances`,
          instancePayload,
          { headers: { Authorization: `Bearer ${authToken}` } }
        );

        showSnackbar('Multi-step Approach Protocol created!', 'success');
        // Optionally fetch protocols again
        fetchProtocols();
      }

    } catch (err) {
      console.error('Error creating approach:', err);
      showSnackbar('Failed to create or launch approach.', 'error');
    }
  };

  // 4) Update an existing protocol
  const updateProtocol = async (updatedProtocol) => {
    if (!authToken) return;
    try {
      await axios.put(
        `${API_BASE_URL}/approach/protocols/${updatedProtocol.approach_protocol_id}`,
        {
          name: updatedProtocol.name,
          description: updatedProtocol.description,
          steps: updatedProtocol.steps,
          is_default: updatedProtocol.is_default || false,
          default_from_phone: updatedProtocol.default_from_phone || null,
          default_from_email: updatedProtocol.default_from_email || null
        },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      showSnackbar('Approach Protocol Updated', 'success');
      fetchProtocols();

    } catch (err) {
      console.error('Error updating protocol:', err);
      showSnackbar('Failed to update protocol.', 'error');
    }
  };

  // 5) Delete a protocol
  const deleteProtocol = async (protocolId) => {
    if (!authToken) return;
    try {
      await axios.delete(
        `${API_BASE_URL}/approach/protocols/${protocolId}`,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      showSnackbar('Protocol Deleted', 'info');
      fetchProtocols();

    } catch (err) {
      console.error('Error deleting protocol:', err);
      showSnackbar('Failed to delete protocol.', 'error');
    }
  };

  // Utility for snackbars
  const showSnackbar = (message, severity = 'info') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  return (
    <Box p={3}>
      <Card sx={{ backgroundColor: '#1e1e1e', boxShadow: 3, mb: 4 }}>
        <CardHeader
          avatar={<Avatar sx={{ bgcolor: 'secondary.main' }}>A</Avatar>}
          title={<Typography variant="h5">Approach Protocols</Typography>}
          subheader={
            <Typography variant="body2" color="text.secondary">
              Create and manage your multi-channel outreach approaches here.
            </Typography>
          }
        />
        <CardContent>
          {/* 1) Form for creating new approach */}
          <ApproachProtocolForm onCreate={createProtocol} />

          {/* 2) List of existing protocols */}
          <ApproachProtocolList
            protocols={protocols}
            onUpdate={updateProtocol}
            onDelete={deleteProtocol}
          />
        </CardContent>
      </Card>

      {/* Snackbar for status messages */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ApproachProtocolView;
