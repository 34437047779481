// src/components/outbound/ApproachProtocolList.js

import React from 'react';
import { Box, Typography } from '@mui/material';
import EditApproachProtocolCard from './EditApproachProtocolCard';

const ApproachProtocolList = ({ protocols, onUpdate, onDelete }) => {
  return (
    <Box>
      {protocols.length === 0 && (
        <Typography>No Approach Protocols found.</Typography>
      )}
      {protocols.map((proto) => (
        <EditApproachProtocolCard
          key={proto.approach_protocol_id}
          protocol={proto}
          onUpdate={onUpdate}
          onDelete={onDelete}
        />
      ))}
    </Box>
  );
};

export default ApproachProtocolList;

