// src/components/outbound/OutboundProgressView.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Typography,
  Button,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemText,
  CircularProgress
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { supabase } from '../../supabaseClient';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

// A styled icon button to rotate on expand
const ExpandMoreButton = styled((props) => {
  const { expand, ...other } = props;
  return (
    <Button
      startIcon={<ExpandMoreIcon sx={{ transform: expand ? 'rotate(180deg)' : 'rotate(0deg)' }} />}
      {...other}
    />
  );
})(({ expand }) => ({
  transition: 'transform 0.2s'
}));

const OutboundProgressView = () => {
  const [instances, setInstances] = useState([]);
  const [expandedIds, setExpandedIds] = useState({});
  const [loading, setLoading] = useState(false);
  const [authToken, setAuthToken] = useState(null);
  const [interactions, setInteractions] = useState({});

  useEffect(() => {
    // Fetch the Supabase session token on mount
    const loadSession = async () => {
      try {
        const { data } = await supabase.auth.getSession();
        const token = data.session?.access_token;
        setAuthToken(token);
      } catch (err) {
        console.error('Error getting session token:', err);
      }
    };
    loadSession();
  }, []);

  useEffect(() => {
    if (authToken) {
      fetchInstances();
    }
  }, [authToken]);

  const fetchInstances = async () => {
    if (!authToken) return;
    setLoading(true);
    try {
      const res = await axios.get(`${API_BASE_URL}/approach/instances`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      setInstances(res.data);
    } catch (err) {
      console.error('Error fetching approach instances:', err);
    }
    setLoading(false);
  };

  // Toggle expansion of a single instance row
  const handleExpand = (instanceId) => {
    setExpandedIds((prev) => ({
      ...prev,
      [instanceId]: !prev[instanceId]
    }));
  };

  // Optional "Cancel" approach functionality
  const handleCancel = async (instanceId) => {
    if (!authToken) return;
    try {
      // Replace this with an actual API call if you implement the endpoint.
      alert(`Cancel not yet implemented. You'd call your /instances/${instanceId}/cancel endpoint here.`);
    } catch (err) {
      console.error('Error canceling approach instance:', err);
    }
  };

  // Optional "Run Now" functionality to manually trigger the next step
  const handleRunNow = async (instanceId) => {
    if (!authToken) return;
    try {
      await axios.post(
        `${API_BASE_URL}/approach/instances/${instanceId}/run`,
        {},
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      // After success, re-fetch to see updated status/step
      fetchInstances();
    } catch (err) {
      console.error(`Error running approach instance ${instanceId}`, err);
    }
  };

  // Fetch interactions (inbound/outbound) for the expanded instance
  const loadInteractions = async (instanceId) => {
    if (!authToken) return;
    try {
      const res = await axios.get(`${API_BASE_URL}/approach/instances/${instanceId}/interactions`, {
        headers: { Authorization: `Bearer ${authToken}` }
      });
      setInteractions((prev) => ({
        ...prev,
        [instanceId]: res.data
      }));
    } catch (err) {
      console.error('Error loading interactions:', err);
    }
  };

  const handleExpandAndLoad = async (inst) => {
    const isNowExpanded = !expandedIds[inst.approach_instance_id];
    handleExpand(inst.approach_instance_id);
    if (isNowExpanded && !interactions[inst.approach_instance_id]) {
      // Load interactions only when expanding for the first time
      await loadInteractions(inst.approach_instance_id);
    }
  };

  return (
    <Box p={3}>
      <Card sx={{ backgroundColor: '#1e1e1e' }}>
        <CardHeader
          avatar={<Avatar sx={{ bgcolor: 'secondary.main' }}>O</Avatar>}
          title={<Typography variant="h5">Outbound Progress</Typography>}
          subheader="View each approach instance, track replies, next step timing, etc."
        />
        <CardContent>
          {loading ? (
            <Box sx={{ textAlign: 'center', my: 2 }}>
              <CircularProgress />
            </Box>
          ) : instances.length === 0 ? (
            <Typography>No approach instances found.</Typography>
          ) : (
            instances.map((inst) => {
              const isExpanded = !!expandedIds[inst.approach_instance_id];
              const instanceInteractions = interactions[inst.approach_instance_id] || [];

              return (
                <Card key={inst.approach_instance_id} sx={{ mb: 2, backgroundColor: '#2a2a2a' }}>
                  <CardHeader
                    title={
                      <Typography variant="subtitle1">
                        Objective: {inst.objective}
                      </Typography>
                    }
                    subheader={
                      <Typography variant="body2" color="text.secondary">
                        Status: {inst.status} | Current Step: {inst.current_step_index}
                      </Typography>
                    }
                    action={
                      <Box>
                        <Button
                          color="error"
                          onClick={() => handleCancel(inst.approach_instance_id)}
                          sx={{ mr: 1 }}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => handleRunNow(inst.approach_instance_id)}
                          sx={{ mr: 1 }}
                        >
                          Run Now
                        </Button>
                        <ExpandMoreButton
                          expand={isExpanded}
                          onClick={() => handleExpandAndLoad(inst)}
                          color="info"
                        >
                          {isExpanded ? 'Hide' : 'Show'}
                        </ExpandMoreButton>
                      </Box>
                    }
                  />
                  <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                    <CardContent>
                      {/* Show contact details or any other instance fields */}
                      <Typography variant="body2" sx={{ mb: 1 }}>
                        Contact Email: {inst.contact_email || 'N/A'}<br />
                        Contact Phone: {inst.contact_phone || 'N/A'}
                      </Typography>

                      <Divider sx={{ my: 2 }} />

                      {/* Show interactions (inbound/outbound) if loaded */}
                      <Typography variant="subtitle1">Interactions</Typography>
                      {instanceInteractions.length === 0 ? (
                        <Typography variant="body2" sx={{ color: '#999' }}>
                          No interactions logged yet.
                        </Typography>
                      ) : (
                        <List>
                          {instanceInteractions.map((interaction) => (
                            <ListItem key={interaction.approach_interaction_id} sx={{ pl: 0 }}>
                              <ListItemText
                                primary={`${interaction.direction.toUpperCase()} - ${interaction.channel}`}
                                secondary={interaction.message}
                              />
                            </ListItem>
                          ))}
                        </List>
                      )}
                    </CardContent>
                  </Collapse>
                </Card>
              );
            })
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default OutboundProgressView;
