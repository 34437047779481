// src/components/outbound/EditApproachProtocolCard.js

import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Typography,
  IconButton,
  TextField,
  Button,
  Collapse,
  Box,
  FormControlLabel,
  Switch
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ expand }) => ({
  transform: expand ? 'rotate(180deg)' : 'rotate(0deg)',
  marginLeft: 'auto'
}));

/**
 *  EditApproachProtocolCard displays a single approach protocol.
 *  It allows editing:
 *   - name
 *   - description
 *   - is_default
 *   - default_from_email
 *   - default_from_phone
 *   - steps[]
 *
 *  Then calls onUpdate() with the updated protocol data,
 *  or onDelete() with the protocol's ID.
 */
const EditApproachProtocolCard = ({ protocol, onUpdate, onDelete }) => {
  const [expanded, setExpanded] = useState(false);
  // Make a local copy of the protocol data for edits
  const [localProto, setLocalProto] = useState(protocol);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  // Called when user hits "Save Changes" - calls onUpdate
  const handleSave = () => {
    onUpdate(localProto);
  };

  // Update a top-level field on localProto
  const handleFieldChange = (field, value) => {
    setLocalProto((prev) => ({ ...prev, [field]: value }));
  };

  // Update a field inside steps[]
  const handleStepChange = (index, field, value) => {
    const updatedSteps = [...localProto.steps];
    updatedSteps[index][field] = value;
    setLocalProto((prev) => ({ ...prev, steps: updatedSteps }));
  };

  return (
    <Card sx={{ mb: 2, backgroundColor: '#2a2a2a' }}>
      <CardHeader
        avatar={<Avatar sx={{ bgcolor: 'secondary.main' }}>E</Avatar>}
        title={<Typography variant="subtitle1">{localProto.name}</Typography>}
        subheader={localProto.description}
        action={
          <>
            <IconButton
              color="error"
              onClick={() => onDelete(localProto.approach_protocol_id)}
            >
              <DeleteIcon />
            </IconButton>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {/* Protocol Name */}
          <TextField
            label="Protocol Name"
            variant="filled"
            fullWidth
            sx={{ mb: 2 }}
            value={localProto.name}
            onChange={(e) => handleFieldChange('name', e.target.value)}
          />

          {/* Description */}
          <TextField
            label="Description"
            variant="filled"
            fullWidth
            multiline
            rows={2}
            sx={{ mb: 2 }}
            value={localProto.description || ''}
            onChange={(e) => handleFieldChange('description', e.target.value)}
          />

          {/* is_default */}
          <FormControlLabel
            sx={{ mb: 2 }}
            control={
              <Switch
                checked={!!localProto.is_default}
                onChange={(e) => handleFieldChange('is_default', e.target.checked)}
              />
            }
            label="Make this my default protocol"
          />

          {/* Default From Email */}
          <TextField
            label="Default From Email"
            variant="filled"
            fullWidth
            sx={{ mb: 2 }}
            value={localProto.default_from_email || ''}
            onChange={(e) => handleFieldChange('default_from_email', e.target.value)}
          />

          {/* Default From Phone */}
          <TextField
            label="Default From Phone"
            variant="filled"
            fullWidth
            sx={{ mb: 2 }}
            value={localProto.default_from_phone || ''}
            onChange={(e) => handleFieldChange('default_from_phone', e.target.value)}
          />

          {/* Steps */}
          {Array.isArray(localProto.steps) && localProto.steps.length > 0 && (
            <Box>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Steps
              </Typography>
              {localProto.steps.map((step, idx) => (
                <Box
                  key={idx}
                  sx={{ border: '1px solid #444', p: 2, mb: 1, borderRadius: 1 }}
                >
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    Step {idx + 1}: {step.channel} after {step.delayDays} day(s)
                  </Typography>

                  <TextField
                    label="Channel"
                    variant="filled"
                    fullWidth
                    sx={{ mt: 1, mb: 1 }}
                    value={step.channel}
                    onChange={(e) => handleStepChange(idx, 'channel', e.target.value)}
                  />

                  <TextField
                    label="Delay (days)"
                    variant="filled"
                    fullWidth
                    sx={{ mb: 1 }}
                    value={step.delayDays}
                    onChange={(e) =>
                      handleStepChange(idx, 'delayDays', parseInt(e.target.value) || 0)
                    }
                  />

                  <TextField
                    label="Message Template"
                    variant="filled"
                    fullWidth
                    multiline
                    rows={2}
                    value={step.template || ''}
                    onChange={(e) => handleStepChange(idx, 'template', e.target.value)}
                  />
                </Box>
              ))}
            </Box>
          )}
        </CardContent>

        {/* SAVE CHANGES */}
        <CardActions>
          <Button variant="contained" onClick={handleSave} fullWidth>
            Save Changes
          </Button>
        </CardActions>
      </Collapse>
    </Card>
  );
};

export default EditApproachProtocolCard;

