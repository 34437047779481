// src/components/workflows/ComposerView.js

import React, { useState, useEffect } from 'react';
import { Box, Snackbar, Alert } from '@mui/material';
import { supabase } from '../../supabaseClient';
import axios from 'axios';

import CreateWorkflowForm from './CreateWorkflowForm';
import SavedWorkflowsList from './SavedWorkflowsList';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // e.g. "https://api-dev.echobach.com"

const ComposerView = () => {
  const [workflows, setWorkflows] = useState([]);
  const [tools, setTools] = useState([]);
  const [agents, setAgents] = useState([]);
  const [authToken, setAuthToken] = useState(null);

  // Snackbar state
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  });

  // Helper functions to show/hide the Snackbar
  const showSnackbar = (message, severity = 'info') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  // ─────────────────────────────────────────────────────────────────────────────
  // 1) On mount, load the user's Supabase token (if any)
  // ─────────────────────────────────────────────────────────────────────────────
  useEffect(() => {
    const loadToken = async () => {
      try {
        const { data: sessionData } = await supabase.auth.getSession();
        const token = sessionData.session?.access_token;
        setAuthToken(token);
      } catch (err) {
        console.error('Error getting Supabase session:', err);
      }
    };
    loadToken();
  }, []);

  // ─────────────────────────────────────────────────────────────────────────────
  // 2) GET: /agent_workflows/workflows/
  // ─────────────────────────────────────────────────────────────────────────────
  const fetchWorkflows = async () => {
    if (!authToken) {
      console.warn('No auth token found, cannot fetch workflows');
      return [];
    }

    const response = await fetch(`${API_BASE_URL}/agent_workflows/workflows/`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch workflows');
    }
    return await response.json(); // returns an array of workflow objects
  };

  // ─────────────────────────────────────────────────────────────────────────────
  // 3) POST: /agent_workflows/workflows/
  // ─────────────────────────────────────────────────────────────────────────────
  const createWorkflowDirect = async (payload) => {
    if (!authToken) {
      console.warn('No auth token found, cannot create workflow');
      return null;
    }

    const response = await fetch(`${API_BASE_URL}/agent_workflows/workflows/`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      throw new Error('Failed to create workflow');
    }
    return await response.json();
  };

  // ─────────────────────────────────────────────────────────────────────────────
  // 4) PUT: /agent_workflows/workflows/:id
  // ─────────────────────────────────────────────────────────────────────────────
  const updateWorkflowDirect = async (workflowId, payload) => {
    if (!authToken) {
      console.warn('No auth token found, cannot update workflow');
      return null;
    }

    const response = await fetch(`${API_BASE_URL}/agent_workflows/workflows/${workflowId}`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      throw new Error('Failed to update workflow');
    }
    return await response.json();
  };

  // ─────────────────────────────────────────────────────────────────────────────
  // 5) DELETE: /agent_workflows/workflows/:id
  // ─────────────────────────────────────────────────────────────────────────────
  const deleteWorkflowDirect = async (workflowId) => {
    if (!authToken) {
      console.warn('No auth token found, cannot delete workflow');
      return null;
    }

    const response = await fetch(`${API_BASE_URL}/agent_workflows/workflows/${workflowId}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    if (!response.ok) {
      throw new Error('Failed to delete workflow');
    }
    return await response.json();
  };

  // ─────────────────────────────────────────────────────────────────────────────
  // 6) GET: /agent_workflows/workflows/agent/:assistant_id
  // ─────────────────────────────────────────────────────────────────────────────
  const getAgentWorkflowsDirect = async (assistantId) => {
    if (!authToken) {
      console.warn('No auth token found, cannot get agent workflows');
      return { workflows: [] };
    }

    const response = await fetch(
      `${API_BASE_URL}/agent_workflows/workflows/agent/${assistantId}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error('Failed to fetch agent workflows');
    }
    return await response.json();
  };

  // ─────────────────────────────────────────────────────────────────────────────
  // 7) POST: /agent_workflows/workflows/agent/:assistant_id/update
  // ─────────────────────────────────────────────────────────────────────────────
  const updateAgentWorkflowsDirect = async (assistantId, workflowIds) => {
    if (!authToken) {
      console.warn('No auth token found, cannot update agent workflows');
      return null;
    }

    const response = await fetch(
      `${API_BASE_URL}/agent_workflows/workflows/agent/${assistantId}/update`,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ workflow_ids: workflowIds }),
      }
    );
    if (!response.ok) {
      throw new Error('Failed to update agent workflows');
    }
    return await response.json();
  };

  // ─────────────────────────────────────────────────────────────────────────────
  // 8) On component mount or token update, fetch Workflows, Tools, and Agents
  //    and merge assigned agents into each workflow.
  // ─────────────────────────────────────────────────────────────────────────────
  useEffect(() => {
    const fetchAll = async () => {
      try {
        const workflowData = await fetchWorkflows();

        if (!authToken) {
          console.warn('No auth token found, cannot fetch Tools/Agents');
          return;
        }

        // GET Tools from /assistant/list_tools
        const toolsRes = await axios.get(`${API_BASE_URL}/assistant/list_tools/`, {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        });
        const loadedTools = toolsRes.data.tools || [];
        setTools(loadedTools);

        // GET Agents from /assistant/list_workflow_agents
        const agentsRes = await axios.get(
          `${API_BASE_URL}/assistant/list_workflow_agents`,
          {
            headers: { Authorization: `Bearer ${authToken}` },
            withCredentials: true,
          }
        );
        const loadedAgents = agentsRes.data.agents || [];
        setAgents(loadedAgents);

        // Merge assigned agents into each workflow
        const updatedWorkflows = workflowData.map((w) => ({
          ...w,
          assignedAgents: [],
        }));

        for (const agent of loadedAgents) {
          const agentId = agent.assistant_id;
          const agentWfRes = await getAgentWorkflowsDirect(agentId);
          // agentWfRes.workflows = [ { workflow_id, assigned, ...} ]
          agentWfRes.workflows.forEach((aw) => {
            if (aw.assigned) {
              const idx = updatedWorkflows.findIndex(
                (u) => u.id === aw.workflow_id
              );
              if (idx >= 0) {
                updatedWorkflows[idx].assignedAgents.push(agentId);
              }
            }
          });
        }

        setWorkflows(updatedWorkflows);
      } catch (err) {
        console.error('Error loading data:', err);
      }
    };

    if (authToken) {
      fetchAll();
    }
  }, [authToken]);

  // ─────────────────────────────────────────────────────────────────────────────
  // Handle Agent Assignments (uses the *Direct calls above)
  // ─────────────────────────────────────────────────────────────────────────────
  const handleAgentAssignments = async (workflowId, assignedAgents) => {
    try {
      for (const agent of agents) {
        const agentId = agent.assistant_id;

        // 1) GET existing assigned workflows
        const res = await getAgentWorkflowsDirect(agentId);
        const currentAssignments = res.workflows
          .filter((w) => w.assigned)
          .map((w) => w.workflow_id);

        // 2) Decide to add or remove this workflow ID
        let updatedList = [...currentAssignments];
        if (assignedAgents.includes(agentId)) {
          if (!updatedList.includes(workflowId)) {
            updatedList.push(workflowId);
          }
        } else {
          updatedList = updatedList.filter((id) => id !== workflowId);
        }

        // 3) POST updated list
        await updateAgentWorkflowsDirect(agentId, updatedList);
      }
    } catch (err) {
      console.error('Error in handleAgentAssignments:', err);
      // Show an error snack if something fails
      showSnackbar('Some agent assignments may have failed.', 'error');
    }
  };

  // ─────────────────────────────────────────────────────────────────────────────
  // CREATE
  // ─────────────────────────────────────────────────────────────────────────────
  const handleCreateWorkflow = async (newWorkflow) => {
    try {
      const payload = {
        name: newWorkflow.name,
        primaryObjective: newWorkflow.primaryObjective,
        steps: newWorkflow.steps,
      };

      const created = await createWorkflowDirect(payload);

      // Then assign agents if selected
      if (created?.id && newWorkflow.assignedAgents) {
        await handleAgentAssignments(created.id, newWorkflow.assignedAgents);
      }

      // Keep local state in sync
      setWorkflows((prev) => (created ? [...prev, created] : prev));

      // Show a success snack
      showSnackbar('Workflow created successfully!', 'success');
    } catch (error) {
      console.error('Error creating workflow:', error);
      showSnackbar('Error creating workflow.', 'error');
    }
  };

  // ─────────────────────────────────────────────────────────────────────────────
  // UPDATE
  // ─────────────────────────────────────────────────────────────────────────────
  const handleSaveWorkflow = async (updatedWorkflow) => {
    try {
      const workflowId = updatedWorkflow.id;
      const payload = {
        name: updatedWorkflow.name,
        primaryObjective: updatedWorkflow.primaryObjective,
        steps: updatedWorkflow.steps,
      };

      const saved = await updateWorkflowDirect(workflowId, payload);

      // Then handle agent assignments
      if (saved?.id && updatedWorkflow.assignedAgents) {
        await handleAgentAssignments(workflowId, updatedWorkflow.assignedAgents);
      }

      // Update local state
      setWorkflows((prev) =>
        prev.map((w) => (w.id === saved.id ? saved : w))
      );

      // Show a success snack
      showSnackbar('Workflow updated successfully!', 'success');
    } catch (error) {
      console.error('Error updating workflow:', error);
      showSnackbar('Error updating workflow.', 'error');
    }
  };

  // ─────────────────────────────────────────────────────────────────────────────
  // DELETE
  // ─────────────────────────────────────────────────────────────────────────────
  const handleDeleteWorkflow = async (workflowId) => {
    try {
      await deleteWorkflowDirect(workflowId);
      setWorkflows((prev) => prev.filter((w) => w.id !== workflowId));
      // Show a delete/info snack
      showSnackbar('Workflow deleted.', 'info');
    } catch (error) {
      console.error('Error deleting workflow:', error);
      showSnackbar('Error deleting workflow.', 'error');
    }
  };

  // ─────────────────────────────────────────────────────────────────────────────
  // RENDER
  // ─────────────────────────────────────────────────────────────────────────────
  return (
    <Box p={3}>
      <CreateWorkflowForm
        tools={tools}
        agents={agents}
        onCreate={handleCreateWorkflow}
      />
      <SavedWorkflowsList
        workflows={workflows}
        tools={tools}
        agents={agents}
        onSave={handleSaveWorkflow}
        onDelete={handleDeleteWorkflow}
      />

      {/* SNACKBAR */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ComposerView;
